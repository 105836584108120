import { Trans, useTranslation } from 'react-i18next';
import { namespaces } from '@zenown-insurance/i18n-service';
import './CalculatorScreen.css';
import {
  Grid,
  Typography,
  Card,
  Link,
  Divider,
  Checkbox,
  ListItem,
  IconButton,
  ListItemIcon,
  Dialog,
  ListItemText,
  Button,
  Paper,
  List,
  ListItemSecondaryAction,
  InputAdornment,
  TextField,
  ListItemAvatar,
  ButtonBase,
  Box,
  DialogContent,
  CircularProgress,
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import BlueUnCheckBoxIcon from '../../../../assets/insurance/blueUnCheckBoxIcon.svg';
import BlueCheckBoxIcon from '../../../../assets/insurance/blueCheckBoxIcon.svg';
import { AllianzInsuranceService } from '@zenown-insurance/services/requests';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ReturnButton from './../../../../components/return-button/ReturnButton';
import BikeIcon from './../../../../assets/insurance/bike-icon.svg';
import SheildIcon from './../../../../assets/insurance/sheild-icon.svg';
import ZenOwnLogo from '../../../../assets/insurance/ZenOwn.png';
import ZenOwnLogo2 from '../../../../assets/insurance/ZenOwn@2x.png';
import ArrowRightReceipt from '../../../../assets/insurance/ArrowRightReceipt (2).svg';
import ArrowRightReceiptWhite from '../../../../assets/insurance/ArrowRightReceipt.svg';

import PlatCheck from '../../../../assets/insurance/platCheck@3x.png';
import Sheet from 'react-modal-sheet';
import Receipt from '../../../../assets/insurance/receipt.png';
import Receipt2 from '../../../../assets/insurance/receipt@2x.png';
import Receipt3 from '../../../../assets/insurance/receipt@3x.png';
import PrimaryButton from '../../../../../lib/components/primary-button/PrimaryButton';
import { useContext, useCallback, useRef, useState, useEffect } from 'react';
import InsureProductProcessContext, {
  calculatePremium,
} from '../../../../../lib/contexts/InsureProductProcessContext';
import moment from 'moment';
import { getCurrencyText } from '@zenown-insurance/services/requests';
import { LongFooter } from 'libs/ui/src/lib/components/zenown-insurance/LongFooter';
import DisabledCheckedIcon from './../../../../assets/insurance/disabled-checked-icon.svg';
import CheckedIcon from './../../../../assets/insurance/checked-icon.svg';
import { Footer } from 'libs/ui/src/lib/components/footer/Footer';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
const embeddedInsuranceService = new AllianzInsuranceService();
import { debounce } from 'lodash';

import ErrorMessage from 'libs/ui/src/lib/components/error-message/ErrorMessage';
import { LanguageSection } from 'libs/ui/src/lib/components/zenown-insurance/LanguageSection';
import { UploadPageHeader } from 'libs/ui/src/lib/components/zenown-insurance';
import { UploadSection } from 'libs/ui/src/lib/components/zenown-insurance/UploadSection';
import { isOutsideWorkHours } from '../InsureProductPage';
export function CalculatorScreen(props: any) {
  const {
    calculatorModalOpen,
    tUpload,
    file,
    setFile,
    trackEvent,
    outWorkHours,
    setOutWorkHours,
    setShowPolicy,
    setShowTerms,
    isLoading,
    setIsLoading,
    progress,
    showHowItWorks,
    errorMessage,
    setErrorMessage,
    setIntialPremium,
    uploadFile,
  } = useContext(InsureProductProcessContext);
  const [inputFocused, setInputFocused] = useState(false);
  const [showSubmit, setShowSubmit] = useState(false);
  const [submittedPrice, setSubmittedPrice] = useState(false);

  const t = (key: string) => tUpload('calculator.' + key);
  const [width, setWidth] = useState(375);
  useEffect(() => {
    const element = document.getElementById('layout-box');
    if (element) {
      const positionInfo = element?.getBoundingClientRect();
      setWidth(positionInfo.width);
    }
    document.title = t('title');
    // eslint-disable-next-line
  }, []);
  const scroll = useRef<any>();

  const [legallyAccepted, setLegallyAccepted] = useState(
    localStorage.getItem('legallyAccepted') === 'true'
  );
  const [scrolling, setScrolling] = useState(true);
  const [sheetOpen, setSheetOpen] = useState(false);
  const [price, setPrice] = useState<any>();
  const [offre, setOffre] = useState<any>(undefined);
  const [error, setError] = useState<any>(undefined);
  const insuranceFeatures = [
    {
      description: t('benefitsRisksList.1'),
    },
    {
      description: t('benefitsRisksList.2'),
    },
    {
      description: t('benefitsRisksList.3'),
    },
    {
      description: t('benefitsRisksList.4'),
    },
  ];
  const [seefull, setSeeFull] = useState(true);

  useEffect(() => {
    if (price && !submittedPrice) {
      setShowSubmit(true);
    }
  }, [price, submittedPrice]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingInline: '8px',
      }}
    >
      <UploadPageHeader />
      <div
        style={{
          marginTop: '20px',
          width: 'calc(100% - 32px)',
          background: '#FFFFFF',

          /* Drop Shadow */
          padding: '24px',
          //paddingInline: '0px',

          boxShadow: '0px 4px 30px 1px rgba(0, 55, 129, 0.03)',
          borderRadius: '10px',
          position: 'relative',
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: '12px',
            left: '12px',
            zIndex: 5555,
          }}
        >
          <LanguageSection trackEvent={trackEvent} />
        </div>
        <IconButton
          sx={{ position: 'absolute', top: '19px', right: '12px', padding: 0 }}
          onClick={() => {
            showHowItWorks();
          }}
        >
          <HelpOutlineIcon
            sx={{
              color: '#9CA0AB',
              fontSize: '22px',
              height: '22px',
              width: '22px',
            }}
          />
        </IconButton>
        <div
          style={{
            textAlign: 'center',
            width: '100%',
            marginBottom: '24px',
            marginTop: '43px',
          }}
        >
          <Typography
            variant="h1"
            sx={{
              marginInline: 'auto',
              fontSize: '24px',
              fontWeight: '700',
              lineHeight: '44.5px',
            }}
          >
            {t('title')}
          </Typography>
          <Typography
            variant="h3"
            sx={{
              marginInline: 'auto',
              fontSize: '16px',
              fontWeight: '500',
              lineHeight: '20.8px',
              color: '#9CA0AB',
              marginTop: '8px',
            }}
          >
            {t('subTitle')}
          </Typography>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            gap: '8px',
            maxHeight: '57px',
            minHeight: '57px',
          }}
        >
          {' '}
          <div
            className={inputFocused ? 'focused-input' : ''}
            style={{
              position: 'relative',
              background: '#FFFFFF',
              padding: '16.3px',
              paddingInline: '16px',
              paddingRight: '50px',
              maxHeight: '22.8px',
              minHeight: '22.8px',

              /* Zen Sub-Text */

              border: '1px solid #9CA0AB',
              borderRadius: '8px',

              width: 'calc(100% - 66px)',
              fontSize: '16px',
              fontWeight: '400',
              lineHeight: '20.8px',
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                marginInline: 'auto',
                fontSize: '16px',
                fontWeight: '400',
                lineHeight: '20.8px',
              }}
              style={{
                position: 'absolute',
                right: '18px',
                top: '19px',
                border: 'none',
                zIndex: 99,
              }}
            >
              CHF
            </Typography>
            <input
              type="text"
              pattern="[0-9]*"
              inputMode="numeric"
              onFocus={() => {
                setInputFocused(true);
              }}
              onBlur={() => {
                setInputFocused(false);
              }}
              placeholder="0"
              style={{
                outline: 'none',
                width: 'calc(100% - 50px)',

                background: '#FFFFFF',
                /* Zen Sub-Text */

                border: 'none',

                fontSize: '16px',
                fontWeight: '400',
                lineHeight: '20.8px',
              }}
              value={price}
              onChange={(e: any) => {
                trackEvent({ event: 'Input', action: 'Quote Calculator' });
                let p = e.target.value
                  .replace(/[^0-9.,]+/g, '')
                  .replace('.', ',');

                if (e.target.value) {
                  setPrice(p);
                  setError(undefined);
                  //getPremiumOptions(+price.replace(',', '.'));
                } else {
                  setPrice(undefined);
                  setOffre(undefined);
                }
              }}
            />
          </div>
          {showSubmit && !(sheetOpen || offre) && (
            <Button
              variant="contained"
              disabled={error?.length}
              sx={{
                width: '57px',
                height: '57px',
                borderRadius: '10px',
                boxShadow: 'none',
                '&:hover': {
                  boxShadow: 'none', // Disable shadow on hover
                },
                '&:click': {
                  boxShadow: 'none', // Disable shadow on hover
                },
              }}
              onClick={() => {
                if (+price < 500 || +price > 50000) {
                  setError(t('price.error'));
                  return;
                } else {
                  setOffre(calculatePremium(+price.replace(',', '.')));
                  setSubmittedPrice(true);
                }
              }}
            >
              <img
                src={ArrowRightReceipt}
                style={{
                  width: '14px',
                  height: '11px',
                }}
              />
            </Button>
          )}
        </div>

        {!offre && !sheetOpen && (
          <>
            {' '}
            <Divider
              style={{
                margin: '24px',
                marginInline: '0',
              }}
            >
              <Typography
                sx={{
                  fontWeight: '600',
                  fontSize: '12px',
                  lineHeight: '15.6px',
                  color: '#9CA0AB',
                  opacity: '0.68',
                  verticalAlign: 'middle',
                }}
              >
                {t('or')}
              </Typography>
            </Divider>
            {file?.length ? (
              <div
                style={{
                  width: '100%',
                  position: 'relative',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <UploadSection
                  id="uploadSection"
                  key={'00'}
                  trackEvent={trackEvent}
                  onChange={async (e: any) => {
                    if (e && typeof e?.preventDefault == 'function')
                      e?.preventDefault();
                    setFile(e);
                    setErrorMessage('');
                  }}
                  files={file}
                />
              </div>
            ) : (
              <>
                <ButtonBase
                  onClick={() => {
                    trackEvent({
                      event: 'Button Click',
                      action: 'Insure Your Watch',
                    });
                    setSheetOpen(true);
                  }}
                  sx={{
                    padding: 0,
                    width: '100%',
                    height: '100%',
                    display: 'block',
                    borderRadius: '12px',
                  }}
                >
                  <List sx={{ padding: '0', listStyle: 'none !important' }}>
                    {' '}
                    <ListItem
                      sx={{
                        alignItems: 'center',
                        listStyle: 'none !important',
                      }}
                      alignItems="center"
                    >
                      <ListItemAvatar>
                        <img
                          src={Receipt}
                          srcSet={`${Receipt} 1x, ${Receipt2} 2x, ${Receipt3} 3x`}
                          style={{
                            width: '30px',
                            height: '34px',
                          }}
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <Typography
                            sx={{
                              marginTop: '3px',
                              color: '#0179FF',
                              fontSize: '16px',
                              fontWeight: '600',
                              lineHeight: '20.8px',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {t('receipt.title')}
                          </Typography>
                        }
                        secondary={
                          <Typography
                            sx={{
                              color: '#9CA0AB',
                              fontSize: '14px',
                              fontWeight: '500',
                              lineHeight: '18.2px',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {t('receipt.subTitle')}
                          </Typography>
                        }
                      />
                      <ListItemSecondaryAction sx={{ right: '8px' }}>
                        <img
                          src={ArrowRightReceiptWhite}
                          style={{
                            width: '14px',
                            height: '11px',
                          }}
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                  </List>{' '}
                </ButtonBase>
              </>
            )}
          </>
        )}
        {Boolean(error) && <ErrorMessage message={error} />}
      </div>{' '}
      <Typography variant="h2" sx={{ marginTop: '20px', width: '100%' }}>
        {t('benefitsRisksList.title')}
      </Typography>
      <ButtonBase
        sx={{ width: '100%', borderRadius: '12px' }}
        onClick={() => setSeeFull(!seefull)}
        disabled
      >
        {' '}
        <div
          style={{
            position: 'relative',
            width: '100%',
          }}
        >
          {' '}
          <List
            ref={scroll}
            onScroll={() => {
              setScrolling(scrolling);
              console.log(scroll.current.scrollTop);
            }}
            sx={{
              padding: 0,
              maxHeight: !seefull ? '160px' : '',
              overflowY: 'clip',
              position: 'relative',
              marginTop: '6px',
              width: '100%',
              paddingBottom: '30px',
            }}
          >
            {insuranceFeatures.map((feat, index) => (
              <ListItem
                alignItems="center"
                key={index}
                sx={{ padding: '0px', marginTop: '15px' }}
              >
                <ListItemText
                  sx={{ margin: '0' }}
                  primary={
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                      }}
                    >
                      <img
                        src={false ? PlatCheck : CheckedIcon}
                        style={{
                          height: '20px',
                          width: '20px',
                          marginRight: '10px',
                          verticalAlign: 'middle',
                        }}
                        alt="Checkmark icon"
                      />
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          flexGrow: 1,
                        }}
                      >
                        {' '}
                        <Typography
                          variant="caption"
                          sx={{
                            fontWeight: '500!important',
                            letterSpacing: '-0.3px!important',
                            fontSize: '12.5px !important',
                            lineHeight: '18.2px!important',
                          }}
                        >
                          {feat.description}
                        </Typography>
                        {false && (
                          <div
                            style={{
                              padding: '1px',
                              maxHeight: '18px',
                              paddingInline: '0px',
                              color: 'white',
                              fontWeight: '500',
                              fontSize: '12px',
                              lineHeight: '18px',
                              maxWidth: '110px',
                              minWidth: '110px',
                              textAlign: 'center',
                              background:
                                'radial-gradient(148.08% 148.08% at 0% -21.15%, #EBECEF 0%, #8DA4D2 100%)',
                              borderRadius: '4px',
                            }}
                          >
                            {t('benefitsRisksList.plat')}
                          </div>
                        )}
                      </div>
                    </div>
                  }
                />
              </ListItem>
            ))}
          </List>{' '}
          {false && !seefull && (
            <div
              style={{
                position: 'absolute',
                bottom: '0px',
                width: '100%',
                left: '0',
                zIndex: '99',
                height: '112px',

                background:
                  'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%)',
                transform: 'rotate(180deg)',
              }}
            ></div>
          )}
        </div>
      </ButtonBase>
      <div style={{ marginTop: '15px' }}>
        <Button
          variant="text"
          sx={{
            marginTop: '15px',
            marginBottom: '15px',

            padding: '0',
            fontStyle: 'Semibold S',
          }}
          onClick={() => {
            setShowTerms(true);
          }}
        >
          {tUpload('termsOfService')}{' '}
        </Button>
      </div>
      {offre && (
        <>
          <div
            onClick={() => setOffre(undefined)}
            style={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              zIndex: 2,
              opacity: '0.3',
              backgroundColor: 'transparent',
            }}
          ></div>{' '}
          <Sheet
            style={{
              maxWidth: '500px',
              margin: '0 auto',
              zIndex: 999,
              padding: 0,
            }}
            isOpen={offre}
            disableDrag={true}
            onClose={() => {
              setOffre(false);
            }}
          >
            <Sheet.Container
              style={{
                maxWidth: '500px',
                margin: '0 auto',
                zIndex: 999,
                height: '140px',
                maxHeight: '140px',
                padding: 0,
                borderRadius: 0,
                //padding: '16px',
              }}
            >
              <Sheet.Header
                style={{
                  height: 0,
                  display: 'none',
                }}
              />
              <Sheet.Content style={{ padding: '16px' }}>
                <div
                  style={{
                    borderTop: '1px solid #DFE0E5',
                    background: 'white',
                    zIndex: '99',
                    padding: '15px',
                    paddingTop: '22px',
                    paddingBottom: '0px',
                    /* Drop Shadow */

                    //boxShadow: '0px 4px 30px 1px rgba(0, 55, 129, 0.03)',
                    //width: 'calc( 100% + 30px )',

                    //boxShadow: '0px 4px 30px 1px rgba(0, 55, 129, 0.03)',
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: '500',
                      lineHeight: '19px',
                    }}
                  >
                    {t('yourquote')}
                  </Typography>
                  <div
                    style={{
                      marginTop: '7px',
                      marginBottom: '8px',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    {' '}
                    <Typography
                      sx={{
                        fontSize: '24px',
                        fontWeight: '700',
                        lineHeight: '28px',
                      }}
                    >
                      CHF {' ' + calculatePremium(+price.replace(',', '.'))}
                    </Typography>
                    <Button
                      variant="contained"
                      sx={{
                        width: '143px',
                        height: '48px',
                        borderRadius: '10px',
                      }}
                      onClick={() => {
                        setSheetOpen(true);
                        setOffre(false);
                      }}
                    >
                      {t('continue')}
                    </Button>
                  </div>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      marginInline: 'auto',
                      fontSize: '14px',
                      fontWeight: '400',
                      lineHeight: '21px',
                    }}
                    style={{
                      textAlign: 'left',
                      width: '100%',
                      fontSize: '14px',
                      fontWeight: '400',
                      lineHeight: '21px',
                    }}
                  >
                    <span style={{ color: '#0179FF' }}>* </span>
                    {t('1year')}
                  </Typography>
                </div>
              </Sheet.Content>
            </Sheet.Container>

            <Sheet.Backdrop />
          </Sheet>
        </>
      )}
      {sheetOpen && (
        <>
          <div
            onClick={() => setSheetOpen(false)}
            style={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              zIndex: 2,
              opacity: '0.3',
              backgroundColor: 'transparent',
            }}
          ></div>
          <Sheet
            style={{ maxWidth: '500px', margin: '0 auto', zIndex: 999 }}
            isOpen={sheetOpen}
            onClose={() => {
              setSheetOpen(false);
            }}
          >
            <Sheet.Container
              style={{
                maxHeight: errorMessage
                  ? !file?.length
                    ? '430px'
                    : '380px'
                  : !file?.length
                  ? '410px'
                  : '360px',
                //paddingInline: '16px',
              }}
            >
              <Sheet.Header
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  paddingTop: '12px',
                  height: '5px',
                  maxHeight: '5px',
                }}
              />
              <Sheet.Content style={{}}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    paddingInline: '16px',
                  }}
                >
                  <Typography
                    variant="h1"
                    sx={{
                      marginInline: 'auto',
                      fontSize: '24px',
                      fontWeight: '700',
                      lineHeight: '32px',
                      marginBottom: '24px',
                    }}
                  >
                    {t('receipt.sub')}
                  </Typography>
                  {!file?.length && (
                    <>
                      {' '}
                      <ButtonBase
                        onClick={() => {
                          trackEvent({
                            event: 'Button Click',
                            action: 'Upload a photo of receipt',
                          });
                          let doc = document.getElementById('uploadSection');
                          if (doc) doc.click();
                        }}
                        sx={{
                          padding: 0,
                          width: '100%',
                          height: '100%',
                          display: 'block',
                          borderRadius: '12px',
                        }}
                      >
                        {' '}
                        <div
                          style={{
                            boxSizing: 'border-box',

                            height: '138px',
                            width: '100%',

                            /* primary/20 */

                            background: '#F8FBFF',
                            /* primary/60 */

                            border: '2px dashed #E0EFFF',
                            borderRadius: '10px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                          }}
                        >
                          {' '}
                          <img
                            src={Receipt}
                            srcSet={`${Receipt} 1x, ${Receipt2} 2x, ${Receipt3} 3x`}
                            style={{
                              width: '30px',
                              height: '34px',
                              marginTop: '14px',
                            }}
                          />
                          <Typography
                            sx={{
                              marginTop: '14px',
                              fontSize: '14px',
                              fontWeight: '600',
                              lineHeight: '21px',
                            }}
                          >
                            {t('receipt.title')}
                          </Typography>
                          <Typography
                            sx={{
                              color: '#9CA0AB',
                              marginTop: '2px',
                              fontSize: '12px',
                              fontWeight: '500',
                              lineHeight: '17.2px',
                              maxWidth: '160px',
                            }}
                          >
                            {t('receipt.subTitle')}
                          </Typography>
                        </div>
                      </ButtonBase>
                    </>
                  )}
                  {
                    <div
                      style={
                        file?.length
                          ? {
                              width: '100%',
                              marginTop: '24px',
                              position: 'relative',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }
                          : { display: 'none' }
                      }
                    >
                      <UploadSection
                        id="uploadSection"
                        key={'0'}
                        trackEvent={trackEvent}
                        onChange={async (e: any) => {
                          console.log('-------------');
                          setFile(e);
                          setErrorMessage('');
                        }}
                        files={file}
                      />
                    </div>
                  }
                  {errorMessage ? (
                    <div
                      style={{
                        marginTop: '8px',
                      }}
                    >
                      <ErrorMessage message={errorMessage} />
                    </div>
                  ) : null}
                  <ListItem
                    disablePadding
                    alignItems="flex-start"
                    sx={{
                      maxWidth: '100%',
                      marginTop: '20px',
                      gap: '0px',
                      paddingTop: '0px',
                      cursor: 'pointer',
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        maxWidth: '24px',
                        minWidth: '24px !important',
                        maxHeight: '24px',
                        padding: 0,
                      }}
                    >
                      <Checkbox
                        onClick={() => {
                          if (!legallyAccepted) {
                            trackEvent({
                              event: 'Button click',
                              action: 'Consent check',
                            });
                          } else {
                            trackEvent({
                              event: 'Button click',
                              action: 'Consent uncheck',
                            });
                          }
                          let newValue = !legallyAccepted;
                          setLegallyAccepted(newValue);
                          localStorage.setItem(
                            'legallyAccepted',
                            `${newValue}`
                          );
                        }}
                        checkedIcon={
                          <img
                            style={{ width: '24px', height: '24px' }}
                            src={BlueCheckBoxIcon}
                          />
                        }
                        icon={
                          <div
                            style={{
                              width: '24px',
                              height: '24px',
                              minWidth: '24px',
                              border: '1px solid #9CA0AB',
                              borderRadius: '4px',
                            }}
                            //src={BlueUnCheckBoxIcon}
                          />
                        }
                        sx={{
                          verticalAlign: 'center',
                          width: '24px',
                          height: '24px',
                        }}
                        checked={legallyAccepted}
                      />
                    </ListItemIcon>
                    <ListItemText
                      sx={{
                        marginLeft: '14px',
                        cursor: 'default',
                        marginTop: '7px',
                      }}
                    >
                      <Typography
                        variant="subtitle1"
                        color="#383A49"
                        sx={{
                          textAlign: 'start',
                          fontSize: '12px',
                          fontWeight: '400',
                          lineHeight: '17.5px',
                          letterSpacing: '-0.3px',
                        }}
                      >
                        <Trans
                          i18nKey={'legalStatement'}
                          t={t}
                          components={{
                            underline: (
                              <a
                                onClick={() => {
                                  setShowPolicy(true);
                                }}
                                style={{
                                  textDecoration: 'underline',
                                  color: '#0179FF',
                                  cursor: 'pointer',

                                  //fontSize: '12px',
                                }}
                              >
                                {t('legalStatement')}
                              </a>
                            ),
                            underlineTerms: (
                              <a
                                onClick={() => {
                                  setShowTerms(true);
                                }}
                                style={{
                                  textDecoration: 'none',

                                  cursor: 'pointer',
                                  //fontSize: '12px',
                                }}
                              >
                                {t('legalStatement')}
                              </a>
                            ),
                          }}
                        ></Trans>
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  <PrimaryButton
                    loadingLogic={true}
                    disabled={Boolean(
                      !file ||
                        !file.length ||
                        errorMessage ||
                        progress ||
                        !legallyAccepted
                    )}
                    onClick={async () => {
                      trackEvent({
                        event: 'Button click',
                        action: 'Continue',
                      });
                      if (isOutsideWorkHours()) {
                        setOutWorkHours(true);
                        trackEvent({
                          event: 'Page Visit',
                          action: 'Service currently not available',
                        });
                      } else {
                        setIsLoading(true);
                        await uploadFile(file);
                        setIsLoading(false);
                      }

                      /**/
                    }}
                    sx={{ fontStyle: 'Semibold S', marginTop: '24px' }}
                  >
                    {t('uploadButton')}
                  </PrimaryButton>
                </div>
              </Sheet.Content>
            </Sheet.Container>

            <Sheet.Backdrop />
          </Sheet>
        </>
      )}
    </div>
  );
}

export default CalculatorScreen;
