import { Button, Grid, MenuItem, Select } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { namespaces } from '@zenown-insurance/i18n-service';
import { FlagIcon } from 'react-flag-kit';
import './lang.css';
const useStyles = makeStyles({
  selected: {
    color: '#0179FF',
  },
  unSelected: {
    color: '#CDCFD5',
  },
  select: {
    '.MuiSelect-select': {
      padding: '0px !important',
    },
  },
});

export const LanguageSection = ({ trackEvent }: any) => {
  const classes = useStyles();
  const { i18n } = useTranslation(namespaces.pages.uplaodPage);

  const [selectedLang, setSelectedLang] = useState(
    (localStorage.getItem('lang') || 'en').toLowerCase()
  );

  const updateLang = (event: any) => {
    const lang = event.target.value as string;
    trackEvent({
      event: 'Button click',
      action: lang,
    });

    setSelectedLang(lang);
    localStorage.setItem('lang', lang);
    i18n.changeLanguage(lang.toLocaleLowerCase());
    localStorage.setItem('i18nextLng', lang);
  };

  const languages = [
    { code: 'de', flag: 'DE' },
    { code: 'en', flag: 'GB' },
    { code: 'fr', flag: 'FR' },
    { code: 'it', flag: 'IT' },
    // Add more languages here...
  ];

  return (
    <div className="lang">
      <Select
        value={
          languages.find(
            (w) =>
              w.code.toLocaleLowerCase() === selectedLang.toLocaleLowerCase()
          )?.code
        }
        size="small"
        onChange={updateLang}
        className={classes.select}
        inputProps={{
          style: {
            borderRadius: '8px',
            border: '1px solid #D9E7F8',
            background: '#FFF',
            /* Drop Shadow */
            boxShadow: '0px 4px 30px 1px rgba(0, 55, 129, 0.03)',
            fontSize: '14px',

            fontWeight: '600',
            lineHeight: '21px',
            verticalAlign: 'middle',
          },
        }}
        sx={{
          verticalAlign: 'middle',
        }}
      >
        {languages.map((lang) => (
          <MenuItem
            key={lang.code}
            value={lang.code}
            sx={{
              fontWeight: '600',
              lineHeight: '21px',
              fontSize: '14px',
              height: '36px',
            }}
          >
            <FlagIcon
              code={lang.flag as any}
              size={15}
              style={{
                borderRadius: '50%',
                verticalAlign: 'middle',
                marginRight: '5px',
              }}
            />
            {lang.code.toUpperCase()}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};
