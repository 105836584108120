import { makeStyles } from '@mui/styles';
import {
  Grid,
  Typography,
  TextField,
  Autocomplete,
  Box,
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
  Radio,
  SvgIcon,
  List,
  Dialog,
  DialogContent,
  Button,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import PrimaryButton from '../primary-button/PrimaryButton';
import { useContext, useEffect, useState, createRef, useRef } from 'react';
import InsureProductProcessContext from '../../contexts/InsureProductProcessContext';
import { UserInfo } from '@zenown-insurance/services/requests';
import AppleIcon from './../../assets/insurance/apple-icon.svg';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { namespaces } from '@zenown-insurance/i18n-service';
import './datePickerStyles.css';
import { ReactComponent as CheckedRoundedCheckbox } from '../../assets/insurance/checked-rounded-checkbox.svg';
import Birthdaycake from '../../assets/insurance/birthdaycake.png';
import Birthdaycake2 from '../../assets/insurance/birthdaycake@2x.png';
import Birthdaycake3 from '../../assets/insurance/birthdaycake@3x.png';
import BlueUnCheckBoxIcon from '../../assets/insurance/blueUnCheckBoxIcon.svg';
import BlueCheckBoxIcon from '../../assets/insurance/blueCheckBoxIcon.svg';
import { getDaysInMonth, isLeapYear } from 'date-fns';
const minDate = new Date(moment().subtract(18, 'years').toDate());
const DateSelect = ({ currentDate, setCurrentDate, t }: any) => {
  const monthRef: any = useRef<any>(null);

  const dayRef: any = useRef<any>(null);
  const yearRef: any = useRef<any>(null);
  const [scrolled, setScrolled] = useState(false);

  const [selectedDate, setSelectedDate] = useState<any>({
    year: currentDate ? +new Date(currentDate).getFullYear() : 1990,
    month: currentDate ? +new Date(currentDate).getMonth() : 5,
    day: currentDate ? +new Date(currentDate).getDate() : 15,
  });

  const isDisabledMonth = (month: number) => {
    if (selectedDate.year !== new Date().getFullYear() - 18) return false;

    if (month > new Date().getMonth()) {
      return true;
    }
    return false;
  };
  const isDisabledDay = (day: number) => {
    if (selectedDate.year !== new Date().getFullYear() - 18) return false;

    let { month } = selectedDate;
    if (month - 1 === new Date().getMonth() && day + 1 > new Date().getDate()) {
      return true;
    }
    if (month - 1 > new Date().getMonth()) {
      return true;
    }

    return false;
  };

  const itemHeight = 50;
  const visibleItems = 6;

  const [daysInMonth, setDaysInMonth] = useState(1);

  const years = Array.from({ length: 100 }, (_, i) => 1944 + i).filter(
    (x) => new Date().getFullYear() - x >= 18
  );
  const months = Array.from({ length: 12 }, (_, i) => i + 1);

  useEffect(() => {
    let days = getDaysInMonth(
      new Date(selectedDate.year, selectedDate.month - 1)
    );
    if (selectedDate.month === 1 && isLeapYear(selectedDate.year)) {
      days = 29;
    }
    setDaysInMonth(days);
  }, [selectedDate.month, selectedDate.year]);

  const handleScroll = (e: any, type: any) => {
    const element = e.target;
    let middleElementIndex = Math.round(element.scrollTop / itemHeight);
    let v: any = selectedDate;
    if (type === 'year') middleElementIndex = middleElementIndex + 1943;
    v[type] = middleElementIndex + (type === 'month' ? 1 : 1);
    if (
      new Date(v.year, v.month - 1, v.day).getTime() - new Date().getTime() >
      18
    ) {
      v.year = v.year - 1;
    }
    setSelectedDate(v);
    setCurrentDate(new Date(v.year, v.month - 1, v.day));
  };

  useEffect(() => {
    const yearIndex = selectedDate.year - 1944;
    const monthIndex = selectedDate.month;
    const dayIndex = selectedDate.day - 1;
    if (monthRef.current) {
      monthRef.current.scrollTo({
        top: monthIndex * itemHeight,
        behavior: 'instant',
        inline: 'center',
      });
    }

    if (yearRef.current) {
      yearRef.current.scrollTo({
        top: yearIndex * itemHeight,
        behavior: 'instant',
        inline: 'center',
      });
    }

    setTimeout(() => {
      if (dayRef.current) {
        dayRef.current.scrollTo({
          top: dayIndex * itemHeight,
          behavior: 'instant',
          inline: 'center',
        });
        setScrolled(true);
      }
    }, 10);
  }, []);

  return (
    <div
      className="datepickerContent"
      style={{
        display: 'flex',
        //border: '1px solid #ccc',
        width: '100%',
        textAlign: 'center',
        height: itemHeight * 5 + 'px',
        padding: 0,
        opacity: scrolled ? 1 : 0,
        position: 'relative',
      }}
    >
      <div
        style={{
          position: 'absolute',
          height: '50px',
          width: '100%',
          top: '100px',
          borderRadius: '9px',
          background: '#d9e7f8',
          border: 'none',
        }}
      ></div>
      <List
        ref={monthRef}
        style={{
          width: '45%',
          height: itemHeight * visibleItems,
          overflow: 'auto',
          scrollSnapType: 'y mandatory',
          padding: 0,
          //paddingTop: 2 * itemHeight + 'px', // added this
          //paddingBottom: 2 * itemHeight + 'px', // added this
        }}
        onScroll={(e) => handleScroll(e, 'month')}
      >
        <ListItem
          style={{
            height: itemHeight,
            scrollSnapAlign: 'start',
            opacity: 0,
          }}
        ></ListItem>
        <ListItem
          style={{
            height: itemHeight,
            scrollSnapAlign: 'start',
            opacity: 0,
          }}
        ></ListItem>
        {months.map((m, i) => (
          <ListItem
            key={m}
            style={{
              height: itemHeight,
              color:
                Boolean(
                  i === selectedDate.month - 1 ||
                    (i === 0 && selectedDate.month === 0)
                ) && !isDisabledMonth(i)
                  ? '#383a49'
                  : '#9b9b9b',
              scrollSnapAlign: 'start',
            }}
          >
            {t('months.' + m)}
          </ListItem>
        ))}
        <ListItem
          style={{
            height: itemHeight,
            opacity: 0,
          }}
        ></ListItem>
        <ListItem
          style={{
            height: itemHeight,
            opacity: 0,
          }}
        ></ListItem>
        <ListItem
          style={{
            height: itemHeight,
            opacity: 0,
          }}
        ></ListItem>
      </List>

      <List
        ref={dayRef}
        style={{
          width: '27%',
          height: itemHeight * visibleItems,
          overflow: 'auto',
          scrollSnapType: 'y mandatory',
          padding: 0,
          //paddingTop: 2 * itemHeight + 'px', // added this
          //paddingBottom: 2 * itemHeight + 'px', // added this
        }}
        onScroll={(e) => handleScroll(e, 'day')}
      >
        <ListItem
          style={{
            height: itemHeight,
            scrollSnapAlign: 'start',
            opacity: 0,
          }}
        ></ListItem>
        <ListItem
          style={{
            height: itemHeight,
            scrollSnapAlign: 'start',
            opacity: 0,
          }}
        ></ListItem>
        {Array.from({ length: daysInMonth }, (_, i) => i + 1).map((d, i) => (
          <ListItem
            key={d}
            style={{
              height: itemHeight,
              color:
                i === selectedDate.day - 1 && !isDisabledDay(i)
                  ? '#383a49'
                  : '#9b9b9b',
              scrollSnapAlign: 'start',
            }}
          >
            {d}
          </ListItem>
        ))}

        <ListItem
          style={{
            height: itemHeight,
            opacity: 0,
          }}
        ></ListItem>
        <ListItem
          style={{
            height: itemHeight,
            opacity: 0,
          }}
        ></ListItem>
        <ListItem
          style={{
            height: itemHeight,
            opacity: 0,
          }}
        ></ListItem>
      </List>
      <List
        ref={yearRef}
        style={{
          width: '28%',
          height: itemHeight * visibleItems,
          overflow: 'auto',
          scrollSnapType: 'y mandatory',
          padding: 0,
          //paddingTop: 2 * itemHeight + 'px', // added this
          //paddingBottom: 2 * itemHeight + 'px', // added this
        }}
        onScroll={(e) => handleScroll(e, 'year')}
      >
        <ListItem
          style={{
            height: itemHeight,
            scrollSnapAlign: 'start',
            opacity: 0,
          }}
        ></ListItem>
        <ListItem
          style={{
            height: itemHeight,
            scrollSnapAlign: 'start',
            opacity: 0,
          }}
        ></ListItem>
        {years.map((y, i) => (
          <ListItem
            key={y}
            style={{
              height: itemHeight,
              color: 1944 + i === selectedDate.year ? '#383a49' : '#9b9b9b',
              scrollSnapAlign: 'start',
            }}
          >
            {y}
          </ListItem>
        ))}
        <ListItem
          style={{
            height: itemHeight,
            opacity: 0,
          }}
        ></ListItem>
        <ListItem
          style={{
            height: itemHeight,
            opacity: 0,
          }}
        ></ListItem>
        <ListItem
          style={{
            height: itemHeight,
            opacity: 0,
          }}
        ></ListItem>
      </List>
    </div>
  );
};
const IconWithoutPremium = ({ error }: any) => {
  return (
    <SvgIcon
      component={() => (
        <div
          style={{
            width: '24px',
            height: '24px',
            maxWidth: '24px',
            maxHeight: '24px',
            background: '#FFFFFF',
            borderRadius: '50%',
            border: error ? '1px solid red' : '1px solid #DFE0E5',
            margin: '0px',
            marginRight: '15px',
          }}
        ></div>
      )}
      inheritViewBox
      style={{
        marginRight: '15px',
        width: '24px',
        height: '24px',
        maxWidth: '24px',
        maxHeight: '24px',
        color: '#FFFFFF',
        border: 'none',
      }}
    />
  );
};

const IconWithPremium = () => {
  return (
    <SvgIcon
      component={CheckedRoundedCheckbox}
      inheritViewBox
      style={{
        marginRight: '15px',
        width: '24px',
        maxWidth: '24px',
        maxHeight: '24px',
        height: '24px',
        border: 'none',
      }}
    />
  );
};
export const ApplePayForm = () => {
  const {
    registerUser,
    setErrorMessage,
    errorMessage,
    tcommon,
    tUserForm: t,
    apiErrorMessage,
    setApiErrorMessage,
    userInfo,
    setIsLoading,
    trackEvent,
  } = useContext(InsureProductProcessContext);

  const {
    handleSubmit,
    control,
    getValues,
    watch,
    formState,
    clearErrors,
    setError,
    setValue,
  } = useForm({
    shouldFocusError: true,
    reValidateMode: 'onBlur',
    criteriaMode: 'firstError',
    mode: 'onBlur',
    defaultValues: { ...userInfo, country: 'Switzerland' },
  });
  const [dateOpen, setDateOpen] = useState(false);
  const [currentDate, setCurrentDate] = useState();
  const [accept, setAccept] = useState<boolean>(
    Boolean(localStorage.getItem('acceptTerms') || true)
  );

  const fields = [
    {
      id: 'firstName',
      label: t('form.firstName.label'),
      type: 'text',
      rules: {
        required: {
          value: true,
          message: t('form.firstName.errorRequired'),
        },
        minLength: {
          value: 3,
          message: t('form.firstName.errorInvalid'),
        },
      },
    },
    {
      id: 'lastName',
      label: t('form.lastName.label'),
      type: 'text',
      rules: {
        required: {
          value: true,
          message: t('form.lastName.errorRequired'),
        },
        minLength: {
          value: 3,
          message: t('form.lastName.errorInvalid'),
        },
      },
    },
    {
      id: 'gender',
      label: t('form.gender.label'),
      type: 'text',
      rules: {
        required: {
          value: true,
          message: t('form.gender.errorRequired'),
        },
      },
      rendering: () => (
        <>
          <Controller
            name={'gender'}
            control={control}
            rules={{
              required: {
                value: true,
                message: t('form.dateOfBirth.errorRequired'),
              },
            }}
            render={({
              field: { onChange, value, onBlur },
              fieldState: { error },
            }) => {
              return (
                <Grid
                  container
                  justifyContent={'space-between'}
                  sx={{ maxHeight: '64.54px !important' }}
                >
                  {['male', 'female'].map((genderValue, i) => (
                    <Grid
                      onClick={() => {
                        clearErrors('gender');
                        onChange({ target: { value: genderValue } });
                        trackEvent({
                          event: 'Button Click',
                          action: genderValue,
                        });
                      }}
                      item
                      xs={5.9}
                      key={i}
                      sx={{
                        padding: '10.68px',
                        paddingInline: '8px',
                        borderRadius: '10px',
                        maxHeight: '64.54px !important',
                        ...(error ? { color: 'red' } : {}),

                        border:
                          getValues()['gender'] === genderValue
                            ? '1px solid #0179FF'
                            : error
                            ? '1px solid red'
                            : '1px solid #EDEDED',
                      }}
                    >
                      {' '}
                      <Radio
                        color={true ? 'error' : undefined}
                        style={{ display: 'inline-block' }}
                        icon={
                          getValues()['gender'] === genderValue ? (
                            <IconWithPremium />
                          ) : (
                            <IconWithoutPremium error={error} />
                          )
                        }
                        checkedIcon={
                          getValues()['gender'] === genderValue ? (
                            <IconWithPremium />
                          ) : (
                            <IconWithoutPremium error={error} />
                          )
                        }
                        checked={getValues()['gender'] === genderValue}
                        onChange={(e: any) => {
                          trackEvent({
                            event: 'Button Click',
                            action: genderValue,
                          });
                          onChange(e);
                        }}
                        value={genderValue}
                      />
                      <div
                        style={{
                          display: 'inline-block',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        <Typography
                          variant="caption"
                          sx={
                            getValues()['gender'] !== genderValue
                              ? {
                                  color: '#9CA0AB',
                                  fontWeight: 500,
                                  fontSize: '17px',
                                  lineHeight: '23px',
                                }
                              : {
                                  color: '#383A49',
                                  fontWeight: 500,
                                  fontSize: '17px',
                                  lineHeight: '23px',
                                }
                          }
                        >
                          {t('form.gender.options.' + genderValue)}
                        </Typography>
                      </div>
                    </Grid>
                  ))}
                </Grid>
              );
            }}
          />
        </>
      ),
    },
    {
      id: 'email',
      label: t('form.email.label'),
      type: 'email',
      rules: {
        required: {
          value: true,
          message: t('form.email.errorRequired'),
        },
        pattern: {
          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
          message: t('form.email.errorInvalid'),
        },
      },
    },
    {
      id: 'dob',
      label: t('form.dateOfBirth.label'),
      type: 'date',
      rules: {
        required: {
          value: true,
          message: t('form.dateOfBirth.errorRequired'),
        },
      },
      rendering: () => (
        <>
          {
            <Dialog
              PaperProps={{
                sx: {
                  marginInline: '15px',
                  width: '100%',
                  borderRadius: '10px',
                },
              }}
              sx={{
                borderRadius: '10px',
                overflow: 'hidden',
                marginInline: '0px',
                width: '100%',
              }}
              open={dateOpen}
              fullWidth
              maxWidth="sm"
              onClose={() => {
                setDateOpen(false);
              }}
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogContent
                sx={{
                  paddingTop: '30px',
                  paddingBottom: '20px',
                  paddingInline: '22px',
                  borderRadius: '10px',
                }}
              >
                <Grid
                  container
                  direction="column"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{
                    //marginBottom: '20px',
                    width: '100%',
                  }}
                >
                  <Grid item>
                    <img
                      src={Birthdaycake}
                      srcSet={`${Birthdaycake} 1x, ${Birthdaycake2} 2x, ${Birthdaycake3} 3x`}
                      style={{
                        width: '40px',
                        height: '40px',
                      }}
                    />
                  </Grid>
                  <Grid item sx={{ marginTop: '28px' }}>
                    <Typography
                      variant="h3"
                      sx={{
                        fontWeight: '600',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        textAlign: 'center',
                        fontSize: '24px',
                        lineHeight: '24px',
                      }}
                    >
                      {t('whenBirthdDate')}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    sx={{
                      marginTop: '40px',
                      height: '253px',
                      position: 'relative',
                      width: '100%',
                    }}
                  >
                    <div className="shadow-box">
                      <DateSelect
                        currentDate={currentDate}
                        setCurrentDate={setCurrentDate}
                        t={t}
                      />
                    </div>
                  </Grid>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      marginTop: '40px',
                    }}
                  >
                    <Button
                      variant="text"
                      onClick={async () => {
                        setDateOpen(false);
                      }}
                      sx={{
                        marginBottom: '0px',
                        color: '#383A49',

                        fontStyle: 'Semibold S',
                        width: '138px',
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          fontWeight: '500',
                          fontSize: '16px',
                          lineHeight: '21px',
                          color: '#9CA0AB',
                        }}
                      >
                        {tcommon('cancel')}
                      </Typography>
                    </Button>
                    <Button
                      variant="contained"
                      disabled={
                        new Date(currentDate as any).getTime() >
                        new Date(minDate).getTime()
                      }
                      onClick={async () => {
                        let value: any = new Date(currentDate as any);

                        setValue('dob', value);
                        setDateOpen(false);
                      }}
                      sx={{
                        borderRadius: '10px',
                        padding: '17px 40px 17px 40px',
                        backgroundColor: '#0179FF',
                        width: '138px',
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          fontWeight: '600',
                          fontSize: '16px',
                          lineHeight: '21px',
                          color: '#FFFFFF',
                        }}
                      >
                        {tcommon('continue')}
                      </Typography>
                    </Button>
                  </div>
                </Grid>
              </DialogContent>
            </Dialog>
          }
          <Controller
            name={'dob'}
            control={control}
            rules={{
              required: {
                value: true,
                message: t('form.dateOfBirth.errorRequired'),
              },
            }}
            render={({
              field: { onChange, value, onBlur },
              fieldState: { error },
            }) => {
              return (
                <DatePicker
                  className="custom-date-picker"
                  disableFuture
                  DialogProps={{ id: 'custom-date-picker' }}
                  reduceAnimations={true}
                  inputFormat="dd MMM yyyy"
                  label={t('form.dateOfBirth.label')}
                  maxDate={moment().subtract(18, 'years').toDate()}
                  minDate={moment().subtract(100, 'years').toDate()}
                  openTo="year"
                  open={false}
                  value={value ? value : null}
                  onOpen={() => {
                    setDateOpen(true);
                    if (value) {
                      let v: any = new Date(value);

                      setCurrentDate(v);
                    }
                    clearErrors('dob');
                    if (!errorMessage?.error) setErrorMessage('');
                  }}
                  onClose={() => setDateOpen(false)}
                  onChange={(e: any) => {
                    if (error && !dateOpen) {
                      setError('dob', error);
                      setErrorMessage(error?.message + '');
                    } else {
                      if (!errorMessage) {
                        clearErrors('dob');
                        setErrorMessage('');
                        clearErrors('dob');
                      }
                    }
                    if (typeof e !== 'string')
                      e = moment(e).format('YYYY-MM-DD');
                    clearErrors('dob');
                    onChange(e);
                  }}
                  onAccept={(e: any) => {
                    if (error && !dateOpen) {
                      setError('dob', error);
                      setErrorMessage(error?.message + '');
                    } else {
                      if (!errorMessage) {
                        clearErrors('dob');
                        setErrorMessage('');
                      }
                    }
                    if (typeof e !== 'string')
                      e = moment(e).format('YYYY-MM-DD');
                    clearErrors('dob');
                    onBlur();
                  }}
                  InputProps={{
                    tabIndex: 4,
                    onFocus: (e: any) => {
                      if (getValues('root.dob')?.length) {
                        setDateOpen(true);
                      }
                    },
                  }}
                  renderInput={({ inputRef, inputProps, InputProps }) => (
                    <Box
                      onClick={() => setDateOpen(true)}
                      sx={{
                        display: 'relative',
                        alignItems: 'center',
                        width: '100%',
                        button: {
                          position: 'absolute',
                          right: '15px',
                          marginBottom: '60px',
                        },
                      }}
                    >
                      <TextField
                        onClick={() => setDateOpen(true)}
                        placeholder={t('form.dateOfBirth.label')}
                        sx={{ width: '100%' }}
                        label={t('form.dateOfBirth.label')}
                        ref={inputRef}
                        inputProps={{
                          ...inputProps,
                          tabIndex: 4,
                          enterkeyhint: 'Next',
                          autofocus: true,
                          /* onFocus: (e: any) => {
                            if (!getValues()?.dob?.length) {
                              setDateOpen(true);
                            }
                          },*/
                        }}
                        error={Boolean(error)}
                        onKeyDown={(e: any) => {
                          e.preventDefault();
                        }}
                        onChange={(e: any) => {
                          if (error && !dateOpen) {
                            setError('dob', error);
                            setErrorMessage(error?.message + '');
                          } else {
                            if (!errorMessage) {
                              clearErrors('dob');
                              setErrorMessage('');
                            }
                          }
                          trackEvent({ event: 'Input', action: 'dob' });
                          onChange(e);
                        }}
                        onBlur={() => {
                          if (getValues()['dob']) clearErrors('dob');
                          trackEvent({ event: 'Input', action: 'dob' });
                          //onBlur();
                        }}
                      />
                      {InputProps?.endAdornment}
                    </Box>
                  )}
                />
              );
            }}
          />
        </>
      ),
    },
    {
      id: 'country',
      label: t('form.country.label'),
      type: 'text',
      rules: {
        required: {
          value: true,
          message: t('form.country.errorRequired'),
        },
      },
    },
    {
      id: 'state',
      label: t('form.state.label'),
      type: 'text',
      rules: {
        required: {
          value: true,
          message: t('form.state.errorRequired'),
        },
      },
    },
    {
      id: 'city',
      label: t('form.city.label'),
      type: 'text',
      rules: {
        required: {
          value: true,
          message: t('form.city.errorRequired'),
        },
      },
    },
    {
      id: 'postal_code',
      label: t('form.postal_code.label'),
      type: 'text',
      rules: {
        required: {
          value: true,
          message: t('form.postal_code.errorRequired'),
        },
      },
    },
    {
      id: 'address',
      label: t('form.address.label'),
      type: 'text',
      rules: {
        required: {
          value: true,
          message: t('form.address.errorRequired'),
        },
        minLength: {
          value: 3,
          message: t('form.address.errorInvalid'),
        },
      },
    },
  ];

  const RenderInput = (fieldKey: string, order: any) => {
    const field: any = fields.find((k) => k.id === fieldKey);
    return (
      <>
        {field.rendering ? (
          field.rendering()
        ) : (
          <Controller
            name={field.id}
            control={control}
            rules={field.rules}
            render={({
              field: { onChange, value, onBlur },
              fieldState: { error },
            }) => (
              <TextField
                defaultValue={''}
                error={Boolean(error)}
                label={field.label}
                InputProps={{
                  ...(field.id === 'email' ? { type: 'email' } : {}),
                  tabIndex: field.id === 'country' ? -1 : order,
                  style: {
                    textTransform:
                      field.id === 'email' ? 'lowercase' : 'capitalize',
                  },
                }}
                {...(field.id === 'email' ? { type: 'email' } : {})}
                inputProps={{
                  tabIndex: field.id === 'country' ? -1 : order,
                  autofocus: true,

                  style: {
                    textTransform:
                      field.id === 'email' ? 'lowercase' : 'capitalize',
                  },
                }}
                fullWidth
                onChange={(e: any) => {
                  if (field.id === 'country') return;
                  if (error) {
                    setError(field.id, error);
                    setErrorMessage(error?.message + '');
                  } else {
                    if (!errorMessage) {
                      clearErrors(field.id);
                      setErrorMessage('');
                    }
                  }
                  onChange(e);
                }}
                onKeyUp={(e: any) => {
                  if (field.id === 'city') {
                    e.preventDefault();

                    // e.target.blur();
                  }
                }}
                onBlur={(e: any) => {
                  if (getValues()[field?.id]) clearErrors(field.id);
                  if (field.id === 'city') {
                    if (document?.activeElement) {
                      setTimeout(() => {
                        //@ts-ignore
                        document?.activeElement?.blur();
                      }, 0);
                      //@ts-ignore
                      document?.activeElement?.blur();
                    }
                    onBlur();
                  }
                  trackEvent({ event: 'Input', action: field.id });
                  //onBlur();
                }}
                value={field.id === 'country' ? 'Switzerland' : value}
              />
            )}
          />
        )}
      </>
    );
  };
  useEffect(() => {
    watch();
  }, [errorMessage]);

  useEffect(() => {
    trackEvent({ event: 'Page Visit', action: 'Enter Your Details' });
    if (!apiErrorMessage) return;
    let value: any = undefined;

    setErrorMessage();
    console.log('value', value);
    console.log('apiErrorMessage', apiErrorMessage);
    if ((apiErrorMessage + '')?.includes('email')) {
      setValue('email', undefined);
      setError(
        'email',
        {
          type: 'custom',
          message: t('form.email.errorInvalid'),
        },
        { shouldFocus: true }
      );

      value = t('form.email.errorInvalid');

      console.log('email ', value);
    }
    if ((apiErrorMessage + '')?.includes('address')) {
      setValue('address', undefined);
      setError(
        'address',
        {
          type: 'custom',
          message: t('form.address.errorInvalid'),
        },
        { shouldFocus: true }
      );

      value = (value ? value + ', ' : '') + t('form.address.errorInvalid');

      console.log('address ', value);
    }
    if ((apiErrorMessage + '')?.includes('first')) {
      setValue('firstName', undefined);
      setError(
        'firstName',
        {
          type: 'custom',
          message: t('form.firstName.errorInvalid'),
        },
        { shouldFocus: true }
      );

      value = (value ? value + ', ' : '') + t('form.firstName.errorInvalid');

      console.log('firstName ', value);
    }
    if ((apiErrorMessage + '')?.includes('last')) {
      setValue('lastName', undefined);
      setError(
        'lastName',
        {
          type: 'custom',
          message: t('form.lastName.errorInvalid'),
        },
        { shouldFocus: true }
      );

      value = (value ? value + ', ' : '') + t('form.lastName.errorInvalid');

      console.log('lastname ', value);
    }
    if (!value?.length && apiErrorMessage?.length) {
      value = t('form.generalError');
    }
    setTimeout(() => {
      setApiErrorMessage(value);
    }, 0);
    setApiErrorMessage(value);
  }, []);
  useEffect(() => {
    if (Object.keys(formState.errors).length) {
      const error: any =
        //@ts-ignore
        formState?.errors[
          Object.keys(formState?.errors)[
            Object.keys(formState?.errors).length - 1
          ]
        ];
      setErrorMessage(error.message);
    } else if (!errorMessage?.error && !errorMessage?.includes('Oops')) {
      setErrorMessage('');
    }
  }, [formState]);
  useEffect(() => {
    if (dateOpen)
      setTimeout(() => {
        clearErrors('dob');
      }, 0);
  }, [dateOpen]);

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-around"
      alignItems="stretch"
    >
      <form
        onSubmit={handleSubmit(async (data: any) => {
          setIsLoading(true);
          setTimeout(async () => {
            let dd = String(new Date(data.dob).getDate()).padStart(2, '0'); // Get the day as a string
            let mm = String(new Date(data.dob).getMonth() + 1).padStart(2, '0'); // January is 0, hence need to add 1
            let yyyy = new Date(data.dob).getFullYear();

            let formattedDate = yyyy + '-' + mm + '-' + dd;
            console.log('formattedDate', formattedDate);

            await registerUser(
              {
                ...data,
                sex: data.gender === 'male' ? 0 : 1,
                dob: formattedDate,
              } as UserInfo,
              formState?.isDirty
            );
            setIsLoading(false);
          }, 500);
        })}
      >
        <Grid item sx={{ marginBottom: '20px' }}>
          {RenderInput('firstName', 1)}
        </Grid>
        <Grid item sx={{ marginBottom: '20px' }}>
          {RenderInput('lastName', 2)}
        </Grid>
        <Grid item sx={{ marginBottom: '20px' }}>
          {RenderInput('gender', 3)}
        </Grid>
        <Grid item sx={{ marginBottom: '20px' }}>
          {RenderInput('email', 4)}
        </Grid>
        <Grid item sx={{ marginBottom: '20px' }}>
          {RenderInput('dob', 5)}
        </Grid>

        <Grid item sx={{ marginBottom: '20px' }}>
          {RenderInput('address', 6)}
        </Grid>
        <Grid
          item
          container
          spacing={2}
          columns={16}
          sx={{ marginBottom: '20px' }}
        >
          <Grid item xs={8}>
            {RenderInput('postal_code', 7)}
          </Grid>
          <Grid item xs={8}>
            {RenderInput('city', 8)}
          </Grid>
        </Grid>
        <Grid item sx={{ marginBottom: '20px' }}>
          {RenderInput('country', undefined)}
        </Grid>
        {false && (
          <ListItem
            disablePadding
            alignItems="flex-start"
            onClick={() => {
              let value = Boolean(!accept);
              setAccept(value);
              localStorage.setItem('acceptTerms', value + '');
            }}
            sx={{ maxWidth: '100%', marginTop: '24px', gap: '0px' }}
          >
            <ListItemIcon
              sx={{
                maxWidth: '32px',
                minWidth: '32px !important',
                maxHeight: '24px',
                padding: 0,
              }}
            >
              <Checkbox
                inputProps={{
                  tabIndex: 0,
                }}
                onClick={() => {
                  let value = Boolean(!accept);
                  setAccept(value);
                  localStorage.setItem('acceptTerms', value + '');
                }}
                checkedIcon={
                  <img
                    style={{ width: '24px', height: '24px' }}
                    src={BlueCheckBoxIcon}
                    alt="Checked icon"
                  />
                }
                icon={
                  <img
                    style={{ width: '24px', height: '24px' }}
                    src={BlueUnCheckBoxIcon}
                    alt="Unchecked icon"
                  />
                }
                sx={{
                  verticalAlign: 'center',
                  width: '24px',
                  height: '24px',
                }}
                checked={Boolean(accept)}
              />
            </ListItemIcon>
            <ListItemText sx={{ minWidth: 'calc(100% - 32px);' }}>
              {' '}
              <Typography
                variant="caption"
                sx={{ fontWeight: '500', fontSize: '12px', lineHeight: '18px' }}
              >
                {t('form.agreementCheck')}
              </Typography>
            </ListItemText>
          </ListItem>
        )}
        <PrimaryButton
          loadingLogic={true}
          disabled={
            Boolean(Object.keys(formState.errors).length > 0) || !accept
          }
          tabIndex={
            Boolean(Object.keys(formState.errors).length > 0) || !accept
              ? -1
              : 8
          }
          sx={{ marginTop: '42px', marginBottom: '30px' }}
          type={
            Boolean(Object.keys(formState.errors).length > 0) || !accept
              ? 'button'
              : 'submit'
          }
          onClick={() => {
            trackEvent({ event: 'Button click', action: 'Continue' });
          }}
        >
          {t('continue')}
        </PrimaryButton>
      </form>
    </Grid>
  );
};

export default ApplePayForm;
